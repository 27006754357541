<template>
  <v-dialog v-model="dialog" width="550" :persistent="loading">
    <v-card>
      <v-card-title>Käsittele hyvityslasku</v-card-title>

      <v-card-text>
        <!-- ERRORS -->
        <div v-if="billDateState === 'past'">
          <h4 class="warning--text mb-1">
            Ennen lähettämistä, siirrä laskun päivämäärä vähintään kuluvaan päivään.
          </h4>
        </div>

        <h3 class="mb-1">Lähetä hyvityslasku</h3>

        <p
          v-if="isRopoService && !hasRefundServiceId(invoice)"
          class="font-weight-bold warning--text mt-1"
        >
          Voit kohdistaa hyvityslaskun tarvittaessa myöhemmin Ropo One palvelussa.
        </p>

        <!-- INFO -->
        <p :class="billDateState === 'past' ? 'error--text' : ''">
          Laskun päivä: {{ formatDate(invoice.billDate) }}
        </p>
        <p>Kirjauspäivä: {{ formatDate(invoice.accountDate) }}</p>
        <p>Eräpäivä: {{ formatDate(invoice.dueDate) }}</p>

        <v-btn
          class="mt-1"
          color="info"
          :disabled="billDateState === 'past'"
          :loading="loading"
          @click="sendRefundInvoiceToService"
          >Lähetä hyvityslasku</v-btn
        >
      </v-card-text>

      <v-card-text v-if="!hasRefundServiceId(invoice)">
        <h3 class="mb-1">Kirjaa hyvityslasku lähettämättä laskutuspalveluun</h3>
        <v-form ref="payForm">
          <div style="width: 200px">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" min-width="290">
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(payDate)"
                  dense
                  outlined
                  v-bind="attrs"
                  :rules="validations.required"
                  v-on="on"
                  append-icon="mdi-calendar"
                  label="Maksupäivä"
                  v-prevent-manual-input
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="payDate"
                first-day-of-week="1"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-form>
        <v-btn
          class="mt-1"
          color="info"
          :loading="loading"
          :disabled="billDateState === 'past'"
          @click="createRefundWithoutSending"
          >Kirjaa hyvityslasku</v-btn
        >
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mt-1" color="error" :disabled="loading" outlined @click="dialog = false"
          >Poistu</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions } from "vuex";
import validations from "@/validations";
import invoiceMixins from "../../mixins/invoiceMixins";
import moment from "moment";

export default {
  mixins: [mixins, invoiceMixins],

  props: {
    value: { type: Boolean, default: false },
    invoice: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      loading: false,
      payDate: "",
      menu: false,
      validations,
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    billDateState() {
      const today = moment().format("YYYY-MM-DD");
      const billDate = moment(this.invoice.billDate).format("YYYY-MM-DD");

      if (moment(billDate).isSame(today)) return "today";
      else if (moment(billDate).isAfter(today)) return "future";
      else return "past";
    },
  },

  methods: {
    ...mapActions("invoice", ["sendRefundInvoice", "createRefundInvoiceWithoutSending"]),

    async sendRefundInvoiceToService() {
      try {
        if (confirm(`Haluatko lähettää hyvityslaskun laskutukseen?`)) {
          this.loading = true;
          await this.sendRefundInvoice(this.invoice._id);
          this.loading = false;
          this.dialog = false;
          this.showPopup("Hyvityslaskun lähetys onnistui", "success");
        }
      } catch (err) {
        this.$emit("getdata");
        this.loading = false;
        this.showPopup("Lähetys ei onnistunut. " + err, "error");
      }
    },

    async createRefundWithoutSending() {
      if (this.$refs.payForm.validate()) {
        try {
          // Accept refund invoice
          if (
            confirm(`Haluatko kirjata hyvistyslaskun ilman, että se lähetetään laskutuspalveluun?`)
          ) {
            this.loading = true;
            await this.createRefundInvoiceWithoutSending({
              id: this.invoice._id,
              payDate: this.payDate,
            });
            this.loading = false;
            this.dialog = false;
            this.showPopup("Hyvityslaskun tallennus onnistui", "success");
          }
        } catch (err) {
          this.loading = false;
          this.showPopup("Tallennus ei onnistunut. " + err, "error");
        }
      }
    },
  },
};
</script>

<style></style>
